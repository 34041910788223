@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Vidaloka';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  cursor: none;
  overflow-x: hidden;
  position: relative;
  
  /* Fix background-image syntax */
}

/* Apply black background for screens smaller than 'md' */
@media (max-width: 767px) { 
  body {
    background-image: none;
    background-color: white;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Shadow */
.custom-shadow {
  box-shadow: 0px -25px 20px cyan;
}
.custom-cursor {
  position: fixed;
  width: 20px; /* Size of the cursor */
  height: 20px;
  background-color: white; /* White cursor */
  border-radius: 50%; /* Circular cursor */
  pointer-events: none; /* Prevent interference */
  transform: translate(-50%, -50%);
  opacity: 50%;
  z-index: 9999; /* Stay above all content */
}

.bg-responsive {
  /* Default for large screens */
  background-size: 15%;
}

@media (max-width: 767px) {
  .bg-responsive {
    /* Smaller screens */
    background-size: 30%;
  }
}


/* Optional: Ensure no box shadow below the breakpoint */
